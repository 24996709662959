.tourComponent {
  .MuiPaper-root .MuiDialog-paper {
    background-color: transparent !important ;
  }

  .chatbotInputForm {
    display: flex;
    justify-content: end;

    .chatBoxInputControl {
      width: 100%;
      .MuiFormControl-root {
        width: 100%;
      }
    }
  }
}
.backgroundOverlay {
  position: absolute;
  width: 100vw;
  height: 100%;
  z-index: 1000;
  overflow: hidden !important;
  background-color: rgba(0, 0, 0, 0.9);
  color: $white;
  .overlayElement {
    position: fixed;
    width: 80vw;
    bottom: 5%;
    .content {
      background-color: $white;
      @include border-radius(8px);
      .overlayButton {
        border: 2px solid white;
        display: inline-block;
      }
      .overlayFont {
        color: $primaryTextColor;
        padding: 21px 30px 22px 31px;
        font: 500 normal 16px/20px $font-family-base;
      }
    }
    .mydoc {
      margin-right: 5px;
      margin-left: -3px;
      padding: 12px 16px;
      .goToMyDocs {
        box-shadow: 0 0 10px $buttonShadowColor,
          inset 0 0 18px $buttonShadowColor;
        color: $white;
        font: 500 normal 16px/24px $font-family-base;
        text-wrap: nowrap;
        border: 1px solid $tourGuideBtnBorderColor;
        :hover {
          background-color: $tourGuideBtnBorderColor;
        }
      }
    }
  }

  .dataCopilotElement {
    height: 100%;
    position: relative;
    .step1 {
      display: flex;
      flex-direction: column;
      .calculateMarginTop {
        margin-top: calc(100vh - 346px);
      }

      .arrowSvgBox {
        box-sizing: content-box;
        display: inline;
        .arrowSvg {
          margin-top: 5rem;
        }
      }
      .chatbotInputForm {
        width: 100%;
        > div {
          width: 100%;
          .chatBoxInputControl {
            width: 100%;
            .MuiFormControl-root {
              margin-left: 25%;
              width: 65%;
              .icon-message-sent {
                color: $calenderWeekHeaderBackground;
              }
            }
          }
        }
      }
    }

    .step2 {
      .content {
        background: $white;
      }
      .leftTourPanel {
        height: calc(100vh - 210px);

        .accordionList {
          cursor: pointer;
          height: calc(100vh - 400px);
          overflow-y: scroll;
          overflow-x: hidden;
          @extend .commonListScroll;
          .accordionHeader {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            .arrowBox {
              width: 20px;
              height: 20px;
              .icon-dropdown {
                color: $primaryColorPurple;
                padding-top: 1px;
                font-size: 20px;
                &.icon-rotate-top {
                  transform: rotate(180deg);
                }
              }
            }
          }
          .accordion {
            background-color: $accordianBgColor;
            color: $colorNeutralGrey02;
            width: 95%;
          }
        }
      }
    }

    .content {
      background-color: $white;
      display: inline-block;
      word-wrap: break-word;
      @include border-radius(8px);
      .overlayButton {
        border: 2px solid white;
        display: inline-block;
      }
      .overlayFont {
        color: $primaryTextColor;
        padding: 21px 21px 22px 31px;
        font: 500 normal 16px/20px $font-family-base;
      }
    }
  }
}

.backgroundGreyOut {
  .MuiPaper-root.MuiCard-root {
    background-color: rgba(0, 0, 0, 0.9);
  }
  background-color: rgba(0, 0, 0, 0.4) !important;
  border: 0px solid $accordionUpBgColor !important;
}
